// @refresh reload
import { mount, StartClient } from "@solidjs/start/client";
import * as Sentry from "@sentry/browser";
import { DEV } from "solid-js";
import { isServer } from "solid-js/web";
import { findCustomer } from "./lib/customer-io";

if (!DEV && !isServer) {
  Sentry.init({
    release: "wwwfffdev@" + __APP_VERSION__,
    dsn: "https://b6c118448b31f19889e404aaa02fd960@o4507045557633024.ingest.us.sentry.io/4507372398641152",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/fff\.dev\/api/],

    // Capture Replay for 10% of all sessions,
    // plus 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
  setTimeout(function () {
    const foundCustomerId = findCustomer();
    if (foundCustomerId) {
      let sentryUser = {
        id: foundCustomerId,
      };

      Sentry.setUser(sentryUser);
      Sentry.setContext("customer_io", {
        id: foundCustomerId,
      });
    }
  }, 3000); // wait for customer io to init
}

mount(() => <StartClient />, document.getElementById("app")!);
