import { Router } from "@solidjs/router";
import { FileRoutes } from "@solidjs/start/router";
import { ErrorBoundary, Suspense, onMount } from "solid-js";
import * as Sentry from "@sentry/browser";
import Nav from "~/components/Nav";
import "./app.css";
import "flowbite";

import { createScriptLoader } from "@solid-primitives/script-loader";
import { Link, MetaProvider, Title } from "@solidjs/meta";
import posthog from "posthog-js";
import { getGlobalConfig } from "./lib/config-global";
import { track } from "./lib/customer-io";

export default function App() {
  // from https://fly.customer.io/workspaces/161808/journeys/integrations/customerio
  const customerIOScript = `var _cio = _cio || [];
    (function() {
      var a,b,c;a=function(f){return function(){_cio.push([f].
      concat(Array.prototype.slice.call(arguments,0)))}};b=["load","identify",
      "sidentify","track","page"];for(c=0;c<b.length;c++){_cio[b[c]]=a(b[c])};
      var t = document.createElement('script'),
          s = document.getElementsByTagName('script')[0];
      t.async = true;
      t.id    = 'cio-tracker';
      t.setAttribute('data-site-id', '${getGlobalConfig().customerIO.trackAPI.siteID}');
      t.src = 'https://assets.customer.io/assets/track-eu.js';
      s.parentNode.insertBefore(t, s);
    })();`;
  createScriptLoader({
    src: customerIOScript,
    onLoad() {
      // @ts-expect-error: Let's ignore a compile error like this unreachable code
      const customerIO: any = window && global._cio;
      if (!customerIO) {
        console.warn("Customer.IO not found loaded");
        return;
      }
    },
  });
  onMount(() => {
    posthog.init("phc_Io7VIiZPYuCZZQndnqXiO3eJLvRgaDj7MjuOaDmV1pV", {
      api_host: "https://eu.i.posthog.com",
    });
  });

  // making an arbitrary change to test sentry deploy config
  return (
    <ErrorBoundary
      fallback={(error) => {
        const sentryEventId = Sentry.captureException(error);
        track("client-error-occured", {
          handled: false,
          sentry_event_id: sentryEventId,
        });

        return <p>An error has occured, and was logged.</p>;
      }}
    >
      <Router
        root={(props) => (
          <>
            <MetaProvider>
              <Title>Fun Fun Function</Title>

              <Link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/apple-touch-icon.png"
              />
              <Link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32x32.png"
              />
              <Link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon-16x16.png"
              />
              <Link rel="manifest" href="/site.webmanifest"></Link>

              <Suspense>{props.children}</Suspense>
            </MetaProvider>
          </>
        )}
      >
        <FileRoutes />
      </Router>
    </ErrorBoundary>
  );
}
